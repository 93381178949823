.aboutUs{
    background-color: white

}

.aboutUsHeading{
    font-size: 40px;
    color: rgb(0, 152, 199);
    font-family: "Muli-Semi-Bold";
    text-align: center;
    padding-top: 100px;
    padding-bottom: 50px;
}

.aboutUsDesc{
    font-size: 20px;
    color: #444;
    font-family: "Muli-Medium";
    width: 70%;
    margin-left: 15%;
    line-height: 40px;
    text-align: justify;
    padding-bottom: 100px;
}

@media only screen and (max-width: 600px){
    .aboutUsDesc{
        font-size: 17px;
        line-height: 35px;
    }
}
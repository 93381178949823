*{
  /* outline: 1px solid red; */
}

.navbar2{
    position: fixed;
    background-color: rgb(255, 255, 255);
    width: 100%;
    height: 100px;
    font-family: "Muli-Semi-Bold";
    z-index: 10;
  }
  
  .logo2{
    width: 130px;
    height: 80px;
    margin: 10px;
    margin-left: 20px;
  }

  .hamburgerIcon2{
    height: 100%;
    position: absolute;
    top: 0;
    right: 1%;
    /* display: flex; */
    align-items: center;
    display: none;
    background-color: white;
  }
  
  .navlinkContainer2{
    display: flex;
    gap: 50px;
    font-size: 20px;
    width: 80%;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 10%;
    height: 100%;
    align-items: center;
  }
  
  a.navlink2{
    width: 125px;
    display: flex;
    justify-content: center;
    text-decoration: none;
    color: rgb(49, 50, 51);
    transition: all 0.3s ease;
  }

  a.navlink2:hover{
    color: rgb(0, 152, 199);
    text-decoration: none;
    text-decoration-color: rgb(226, 7, 76);
    transition: all 0.3s ease;
  }

  @media only screen and (max-width: 1200px){
    .navlinkContainer2{
      transform: scale(0.9);
      width: 80%;
      left: 10%;
    }
  }

  @media only screen and (max-width: 1000px){

    .navbar2{
      height: 100px;
    }

    .navlinkContainer2{
      display: none;
    }

    .hamburgerIcon2{
      display: flex;
      /* align-items: center;
      top: 20px; */
      border-style: none;
    }

    /* .logo{
      margin-top: 5px;
      margin-left: 5px;
      margin-bottom: 0px;
    } */

    .logo2{
      transform: scale(0.8);
    }
  }


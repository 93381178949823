.sideBar{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: rgb(238, 238, 238);
    transition: all 0.6s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 50vh;
    /* border-style: solid;
    border-color: rgb(55, 55, 55); */
    /* border-radius: 20px; */
}

.sideBar2{
    position: fixed;
    top: 0;
    left: -50%;
    z-index: 10;
    background-color: rgb(54, 54, 54);
    transition: all 0.6s ease;
    width: 50%;
    height: 50vh;
    /* border-radius: 20px; */
    /* border-style: solid;
    border-color: rgb(55, 55, 55); */
}

.sidelinkContainer{
    height: 90%;
    width: 100%;
    line-height: 50px;
    font-family: "Muli-Bold";
    margin-top: 10%;
}



a.sidelink2{
    /* width: 125px; */
    display: flex;
    justify-content: center;
    text-decoration: none;
    color: rgb(0, 152, 199);
    transition: all 0.3s ease;
  }

  a.sidelink2:hover{
    color: rgb(226, 208, 7);
    text-decoration: none;
    text-decoration-color: rgb(5, 46, 123);
    transition: all 0.3s ease;
  }

@media only screen and (min-width: 1000px){
    .sideBar{
        display: none;
    }
}
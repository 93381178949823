.gallery{
    background-color: white;

}


.galleryHeader{
    font-size: 40px;
    color: rgb(0, 152, 199);
    font-family: "Muli-Semi-Bold";
    text-align: center;
    padding-top: 100px;
    /* padding-bottom: 50px; */
}

.galleryFlexbox{
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    cursor: grab;
    /* overflow: auto; */
    margin-top: 50px;
    padding-bottom: 50px;
    gap: 10px;
}


.galleryPic{
    max-width: 600px;
    height: 400px;
}
.footer{
    width: 100%;
    background-color: #313131;
    padding-top: 100px;
    /* padding-left: 40px;
    padding-right: 40px; */
}

.footerHeading{
    font-size: 40px;
    color: rgb(220, 217, 217);
    font-family: "Muli-Semi-Bold";
    text-align: center;
    /* margin-top: 50px; */
    padding-bottom: 50px;
}

.contactDetails{
    width: 90%;
    margin-left: 5%;
    text-align: center;
    font-size: 17px;
    font-family: "Muli-Regular";
    color: rgb(220, 217, 217);
    line-height: 40px;
    margin-bottom: 50px;
}

.copyright{
    width: 90%;
    margin-left: 5%;
    text-align: center;
    font-size: 17px;
    font-family: "Muli-Regular";
    color: rgb(220, 217, 217);
    margin-top: 50px;
    padding-bottom: 50px;
}

.iframe{
    border-radius: 20px;
    width: 90%;
    margin-left: 5%;
}

.mobile_a_tag{
    color: white;
}
.HS{
    width: 100%;
    background-image: linear-gradient(to left,rgba(0, 213, 213, 0.3), rgba(171, 242, 247, 0.3), white);
    margin-top: 100px;
}

.heroSection{
    display: flex;
    gap: 10px;
    width: 90%;
    margin-left: 5%;
    justify-content: center;
    /* margin-top: 80px; */
    /* margin-bottom: 80px; */
    height: 100vh;
    /* background-image: linear-gradient(to right,rgb(255, 115, 0,0.1), rgba(0, 145, 255, 0.1)); */
}

/* .heroButtons{
    display: inline-block;
} */

.heroSlogan{
    font-size: 55px;
    font-family: "Muli-Semi-Bold";
    width: 50%;
    display: block;
    align-items: center;
    color: rgb(0, 152, 199);
    height: 75%;
    margin: auto auto;
    /* color: rgb(221, 120, 53); */
}

.sloganSub1{
    font-size: 30px;
    font-family: "Muli-Light";
    color: #3e3e3e;
    width: 80%;
    padding-top: 50px;
}

.sloganSub2{
    font-size: 20px;
    font-family: "Muli-Medium";
    color: #3e3e3e;
    width: 80%;
    padding-top: 50px;
}

.sloganSub3{
    font-size: 30px;
    font-family: "Muli-Medium";
    color: rgb(0, 152, 199);
    width: 80%;
    /* padding-top: 50px; */
}

.heroPicContainer{
    width: 700px;
    margin: auto auto;
}

.heroPic{
    width: 100%;
    /* border-radius: 90%; */
    /* border-radius: 95%; */
}

.appointmentButton{
    font-family: "Muli-Semi-Bold";
    font-size: 20px;
    padding: 10px;
    margin:0px;
    margin-right: 10px;
    border-radius: 10px;
    color: rgb(0, 152, 199);
    border-style: solid;
    border-color: rgb(0, 152, 199);
    /* border-width: 1px; */
    background-color: rgb(255, 255, 255);
    transition: all 0.3s ease;
    cursor: pointer;
    width: 300px;
}

.appointmentButton:hover{
    border-color: rgb(226, 193, 7);
    color: rgb(226, 193, 7);
    background-color: white;
}

@media only screen and (max-width: 1200px){
    .heroSlogan{
        transform: scale(0.8);
    }
}

@media only screen and (max-width: 1000px){
    .heroSection{
        display: block;
        margin-top: 40px;
        height: auto;
    }

    .heroSlogan{
        width: 90%;
        text-align: center;
    }

    .sloganSub1{
        font-size: 25px;
        margin-left: 10%;
        font-family: "Muli-Regular";
    }

    .sloganSub2{
        margin-left: 10%;
    }

    .sloganSub3{
        margin-left: 10%;
    }

    .heroPicContainer{
        margin: auto auto;
        padding-bottom: 50px;
    }
}

@media only screen and (max-width: 900px){
    .heroSlogan{
        font-size: 40px;
    }

    .appointmentButton{
        transform: scale(0.8);
    }

    .heroPicContainer{
        width: 400px;
    }
}

@media only screen and (max-width: 600px){
  .heroPicContainer{
        width: 260px;
    }
}